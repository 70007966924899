.v-carousel {
  .carousel {
    .control-arrow {
      margin: 0;
      top: 2px;
      height: calc(100% - 10px);
      background: #cccccca1;
    }
    .thumbs-wrapper {
      margin: 0;
      .thumbs {
        padding: 0;
        margin: 0;
        .thumb {
          width: calc(100% / 3) !important;
          margin: 0;
          padding: 0;
          padding-bottom: calc(100% / 3) !important;
          position: relative;
          img{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            object-fit: contain;
            height: 100%;
          }
        }
      }
    }
  }
}
.slider-wrapper .slide > div{
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.slider-wrapper .slide > div img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  object-fit: contain;
  height: 100%;
}