.v-tabs {
  .nav-tabs {
    .nav-item {
      margin-bottom: 0px;
    }
    .nav-link {
      border: 0px solid transparent;
      border-bottom: 2px solid transparent;
      &.active {
        border-bottom: 2px solid #32b257;
        background-color: transparent
      }
    }
  }
}