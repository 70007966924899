// Variable overrides
$theme-colors: (
  "primary": #337ab7,
  "success": #32b257,
  "danger": #c22026,
  "warning": #f9b115,
  "footer-bg": #c22026
);
$footer-light-bg: #c22026;
$footer-light-color: #ffffff;
$footer-height: 'auto'