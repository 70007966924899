$photo-grid-gutter: 6px;
$photo-grid-col-max-size: 12%;
$photo-grid-picture-spacing: ($photo-grid-gutter / 2) 0;
$photo-grid-picture-background: #EDF2F7;
$photo-grid-picture-background-loaded: #1A202C;
.grid-container {
  position: relative;
  overflow: hidden;

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: $photo-grid-gutter / 2;
    margin-right: $photo-grid-gutter / 2;
    padding-bottom: $photo-grid-gutter;

    .column {
      flex-basis: 0;
      flex-grow: 1;
      flex: 0 0 $photo-grid-col-max-size;
      max-width: $photo-grid-col-max-size;
      padding-left: $photo-grid-gutter / 2;
      padding-right: $photo-grid-gutter / 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .picture {
        margin: $photo-grid-picture-spacing;
        cursor: pointer;
        min-width: 100%;
        max-width: 100%;
        height: auto;
        background-color: $photo-grid-picture-background;
        overflow: hidden;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        .bg {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition-delay: 0.82s;
          transition: 0.75s ease all;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
          opacity: 0;
          visibility: hidden;
        }

        &.loading {
          cursor: default;

          .bg,
          img {
            opacity: 0;
          }
        }

        &.loaded {
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $photo-grid-picture-background-loaded;
            opacity: 0;
            z-index: 1;
            transition: 0.45s ease all;
          }

          &:hover {
            &::before {
              opacity: 0.5;
            }
          }

          .bg,
          img {
            opacity: 1;
            z-index: 0;
            transition: 0.75s ease all;
            transition-delay: 0s;
          }

          img {
            position: relative;
          }

          &:hover {
            .bg,
            img {
              transform: scale(1.02);
            }
          }
        }
      }
    }
  }
}
